import { graphql, PageProps } from "gatsby"
import "twin.macro"
import { SEO } from "../components"
import { GatsbyLayout } from "../gatsby-components"

const PageTemplate = ({ data }: PageTemplateProps) => {
  const { title } = data.markdownRemark.frontmatter
  const { html } = data.markdownRemark

  return (
    <GatsbyLayout>
      <SEO title={title} />
      <div tw="container py-16">
        <section tw="prose mx-auto">
          <h1>{title}</h1>
          <div tw="mt-4" dangerouslySetInnerHTML={{ __html: html }} />
        </section>
      </div>
    </GatsbyLayout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`

type PageTemplateProps = PageProps<{
  markdownRemark: {
    html: string
    frontmatter: {
      title: string
    }
  }
}>

export default PageTemplate
